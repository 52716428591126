





























































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from 'vue-class-decorator'
import { GlobalModule, PricesModule } from '@/store'
import { Payload } from 'vue/interfaces'
import { File, Comment, Logs } from 'vue/types'

import axios from '@/plugins/axios'

@Component({
  async beforeRouteEnter(to: any, from: any, next: any) {
    const result = await axios.get(`/prices/get_one/${to.params.id}`)
    if (result.data.price_id) {
      next()
    } else {
      next('/404')
    }
  },
  metaInfo: {
    title: 'Редактирование обьекта'
  },
  components: {
    Geocoder: () => import('@/components/maps/Geocoder.vue'),
    ImageCarousel: () => import('@/components/pages/ImageCarousel.vue'),
    Comments: () => import('@/components/pages/Comments.vue')
  }
})
export default class EditPrices extends Vue {
  public pageID: string
  public deleteDialog: boolean
  public presentationDialog: boolean

  // $_POST data
  public cords: {
    adress: string;
    lat: number;
    lon: number;
  }

  public images: Array<File>
  public typeId: string
  public usersIds: Array<number>
  public clientsIds: Array<number>
  public objStatusId: string
  public saleStatusId: string
  public price: string
  public cadNumber: string
  public metro: string
  public city: string
  public commonMeters: string
  public kitchenMeters: string
  public placeMeters: string
  public floors: string
  public objectState: string
  public liveMeters: string
  public name: string
  public logs: Array<Logs>
  public paginationLogsPage: number

  public locality: string
  public additionalExpenses: string
  public aspv: number
  public territoryDocument: string
  public territoryFact: string
  public sizeHouse: string
  public foundation: string
  public wallInsulation: string

  public home: string
  public documentsHouse: number
  public documentsGrounds: number
  public documentsGas: number
  public documentsElectro: number

  public gas: string
  public electro: string
  public water: string
  public sewerage: string

  public facade: string
  public internalFacing: string

  public transportReach: string
  public infrastructure: string
  public specialConditions: string
  public wellConnected: string
  public stock: string
  public meterNumber: string
  public accountNumber: string
  public keysHouse: string
  public curator: string
  public owner: string
  public scheme: string

  // Page data
  public users: Array<{[propName: string]: any}>
  public types: Array<{[propName: string]: any}>
  public clients: Array<{[propName: string]: any}>
  public objectStatuses: Array<{[propName: string]: any}>
  public saleStatuses: Array<{[propName: string]: any}>
  public yesno: Array<{[propName: string]: any}>
  public documents: Array<{[propName: string]: any}>

  // Generate text
  public text: string

  // Comments
  public comments: Array<Comment>

  // Presentation
  public presentationAddress: string
  public presentationPrice: string
  public km: string
  public kvm: string
  public kvmM: string
  public sotok: string
  public material: string
  public transport: string
  public presentationInfrastructure: string
  public communications: string
  public presentationImage: object | null

  constructor() {
    super()
    this.deleteDialog = false
    this.pageID = '0'

    this.presentationDialog = false

    // Page data
    this.users = []
    this.clients = []
    this.types = []
    this.objectStatuses = []
    this.saleStatuses = []
    this.documents = []
    this.yesno = []

    // Generate text
    this.text = ''

    // $_POST data
    this.cords = {
      adress: '',
      lat: 0,
      lon: 0
    }
    this.images = []
    this.clients = []
    this.typeId = ''
    this.usersIds = []
    this.clientsIds = []
    this.objStatusId = ''
    this.saleStatusId = ''
    this.price = '0'
    this.cadNumber = ''
    this.metro = ''
    this.city = ''
    this.commonMeters = ''
    this.kitchenMeters = ''
    this.placeMeters = ''
    this.floors = ''
    this.objectState = ''
    this.liveMeters = ''
    this.name = ''
    this.logs = []
    this.paginationLogsPage = 1

    this.locality = ''
    this.additionalExpenses = ''
    this.aspv = 0
    this.territoryDocument = ''
    this.territoryFact = ''
    this.sizeHouse = ''
    this.foundation = ''
    this.wallInsulation = ''

    this.home = ''
    this.documentsHouse = 3
    this.documentsGrounds = 3
    this.documentsGas = 3
    this.documentsElectro = 3

    this.gas = ''
    this.electro = ''
    this.water = ''
    this.sewerage = ''

    this.facade = ''
    this.internalFacing = ''

    this.transportReach = ''
    this.infrastructure = ''
    this.specialConditions = ''
    this.wellConnected = ''
    this.stock = ''
    this.meterNumber = ''
    this.accountNumber = ''
    this.keysHouse = ''
    this.curator = ''
    this.owner = ''
    this.scheme = ''

    // Comments
    this.comments = []

    // Presentation
    this.presentationAddress = ''
    this.presentationPrice = ''
    this.km = ''
    this.kvm = ''
    this.kvmM = ''
    this.sotok = ''
    this.material = ''
    this.transport = ''
    this.presentationInfrastructure = ''
    this.communications = ''
    this.presentationImage = null
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @PricesModule.Action('getCreatePageData') getPageData!: (id: number | string) => Promise<any>
  @PricesModule.Action('updateObject') updateObject!: (payload: Payload) => Promise<any>
  @PricesModule.Action('deleteObject') acceptDeleteObject!: (id: string | number) => Promise<any>
  @PricesModule.Action('getPresentation') getPresentation!: (payload: Payload) => Promise<any>

  get paginationLogsLength() {
    return Math.ceil(this.logs.length / 5)
  }

  get paginationLogs() {
    const start = (this.paginationLogsPage - 1) * 5
    return this.logs.slice(start, start + 5)
  }

  get generateText() {
    let typeName = ''
    if (this.typeId) {
      const findTypeName = this.types.find((type: any) => type.type_id === this.typeId)
      typeName = 'Продается ' + findTypeName?.title.toLowerCase()
    }

    let price = ''
    if (this.price !== '0' && this.price.trim() !== '' && typeName.trim() !== '') {
      price = ' за ' + this.price + ' рублей'
    }

    let cadNumber = ''
    if (this.cadNumber.trim() !== '') cadNumber = ' кадастровый номер - ' + this.cadNumber + ';'

    let metro = ''
    if (this.metro.trim() !== '') metro = ' удаленность от метро - ' + this.metro + ' м;'

    let city = ''
    if (this.city.trim() !== '') city = ' удаленность от города - ' + this.city + ' км;'

    let commonMeters = ''
    if (this.commonMeters.trim() !== '') commonMeters = ' всего кв/м - ' + this.commonMeters + ';'

    let kitchenMeters = ''
    if (this.kitchenMeters.trim() !== '') kitchenMeters = ' кухня (кв/м) - ' + this.kitchenMeters + ';'

    let placeMeters = ''
    if (this.placeMeters.trim() !== '') placeMeters = ' участок (кв/м) - ' + this.placeMeters + ';'

    let floors = ''
    if (this.floors.trim() !== '') floors = ' этажей - ' + this.floors + ';'

    let liveMeters = ''
    if (this.liveMeters.trim() !== '') liveMeters = ' жилое помещение (кв/м) - ' + this.liveMeters + ';'

    let objectState = ''
    if (this.objectState.trim() !== '') objectState = ' состояние объекта - ' + this.objectState + ';'

    let properties = ''
    if (cadNumber.trim() !== '' || metro.trim() !== '' || city.trim() !== '' || commonMeters.trim() !== '') {
      properties = '. Характеристики:' + cadNumber + metro + city + commonMeters + kitchenMeters + liveMeters + placeMeters + floors + objectState
    }

    this.text = typeName + price + properties
    return this.text
  }

  set generateText(text: string) {
    this.text = text
  }

  goToBack() {
    this.$router.push('/prices/')
  }

  save() {
    const payload = {
      name: this.name,
      id: this.pageID,
      images: this.images,
      typeId: this.typeId,
      usersIds: this.usersIds,
      clientsIds: this.clientsIds,
      objStatusId: this.objStatusId,
      saleStatusId: this.saleStatusId,
      price: this.price,
      cords: `${this.cords.lat}, ${this.cords.lon}`,
      adress: this.cords.adress,
      cadNumber: this.cadNumber,
      metro: this.metro,
      city: this.city,
      commonMeters: this.commonMeters,
      kitchenMeters: this.kitchenMeters,
      placeMeters: this.placeMeters,
      floors: this.floors,
      objectState: this.objectState,
      liveMeters: this.liveMeters,
      text: this.text,

      locality: this.locality,
      additionalExpenses: this.additionalExpenses,
      aspv: this.aspv,
      territoryDocument: this.territoryDocument,
      territoryFact: this.territoryFact,
      sizeHouse: this.sizeHouse,
      foundation: this.foundation,
      wallInsulation: this.wallInsulation,

      home: this.home,
      documentsHouse: this.documentsHouse,
      documentsGrounds: this.documentsGrounds,
      documentsGas: this.documentsGas,
      documentsElectro: this.documentsElectro,

      gas: this.gas,
      electro: this.electro,
      water: this.water,
      sewerage: this.sewerage,

      facade: this.facade,
      internalFacing: this.internalFacing,

      transportReach: this.transportReach,
      infrastructure: this.infrastructure,
      specialConditions: this.specialConditions,
      wellConnected: this.wellConnected,
      stock: this.stock,
      meterNumber: this.meterNumber,
      accountNumber: this.accountNumber,
      keysHouse: this.keysHouse,
      curator: this.curator,
      owner: this.owner,
      scheme: this.scheme
    }

    this.updateObject(payload)
      .then(() => {
        this.$noty('success', 'Объект успешно обновлен', 7000).show()
        this.$router.push('/prices/')
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  acceptDelete() {
    this.deleteDialog = false
    this.acceptDeleteObject(this.pageID)
      .then(() => {
        this.$noty('success', 'Объект успешно удален', 7000).show()
        this.$router.push('/prices/')
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  openPresentationDialog() {
    this.presentationAddress = this.cords.adress
    this.presentationPrice = this.price
    this.km = this.city
    this.kvm = this.commonMeters
    this.kvmM = this.sizeHouse
    this.sotok = this.territoryDocument
    this.material = this.home
    this.transport = this.transportReach
    this.presentationInfrastructure = this.infrastructure
    this.communications = `Газ: ${this.gas}, электричество: ${this.electro}, вода: ${this.water}, канализация: ${this.sewerage}`
    this.presentationImage = null

    this.presentationDialog = true
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      if (!file) resolve(null)

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  async generatePresentation() {
    const payload = {
      priceId: this.pageID,
      address: this.presentationAddress,
      price: this.presentationPrice,
      km: this.km,
      kvm: this.kvm,
      kvmM: this.kvmM,
      sotok: this.sotok,
      material: this.material,
      communications: this.communications,
      transport: this.transport,
      infrastructure: this.presentationInfrastructure,
      image: await this.toBase64(this.presentationImage)
    }

    this.getPresentation(payload)
      .then((res) => {
        this.$noty('success', 'Презентация сгенерирована!', 7000).show()
        this.presentationDialog = false
        window.open(res.data.file, '_blank')
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  created() {
    this.pageID = this.$route.params.id
    this.getPageData(this.pageID)
      .then((res) => {
        this.users = res.users
        this.clients = res.clients
        this.types = res.properties.types
        this.objectStatuses = res.properties.object_statuses
        this.saleStatuses = res.properties.sale_statuses
        this.documents = res.properties.documents
        this.yesno = res.properties.yesno

        // Fill page
        try {
          this.images = JSON.parse(res.price.images)
        } catch (err) {
          this.images = []
        }

        this.name = res.price.name
        this.typeId = res.price.type_id
        this.usersIds = res.price.users_ids
        this.clientsIds = res.price.clients_ids
        this.objStatusId = res.price.object_status
        this.saleStatusId = res.price.sale_status
        this.price = res.price.price
        this.cadNumber = res.price.cad_number
        this.metro = res.price.metro
        this.city = res.price.city
        this.commonMeters = res.price.common_meters
        this.kitchenMeters = res.price.kitchen_meters
        this.placeMeters = res.price.place_meters
        this.floors = res.price.floors
        this.objectState = res.price.object_state
        this.liveMeters = res.price.live_meters

        this.locality = res.price.locality
        this.additionalExpenses = res.price.additional_expenses
        this.aspv = res.price.aspv
        this.territoryDocument = res.price.territory_document
        this.territoryFact = res.price.territory_fact
        this.sizeHouse = res.price.size_house
        this.foundation = res.price.foundation
        this.wallInsulation = res.price.wall_insulation

        this.home = res.price.home
        this.documentsHouse = res.price.documents_house
        this.documentsGrounds = res.price.documents_grounds
        this.documentsGas = res.price.documents_gas
        this.documentsElectro = res.price.documents_electro

        this.gas = res.price.gas
        this.electro = res.price.electro
        this.water = res.price.water
        this.sewerage = res.price.sewerage

        this.facade = res.price.facade
        this.internalFacing = res.price.internal_facing

        this.transportReach = res.price.transport_reach
        this.infrastructure = res.price.infrastructure
        this.specialConditions = res.price.special_conditions
        this.wellConnected = res.price.well_connected
        this.stock = res.price.stock
        this.meterNumber = res.price.meter_number
        this.accountNumber = res.price.account_number
        this.keysHouse = res.price.keys_house
        this.curator = res.price.curator
        this.owner = res.price.owner
        this.scheme = res.price.scheme

        const c = res.price.cords.split(',')
        this.cords = {
          adress: res.price.adress,
          lat: Number(c[0]),
          lon: Number(c[1])
        }

        this.text = res.price.text

        this.logs = res.logs

        // Commnets
        this.comments = res.comments
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }
}
